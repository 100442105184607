<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5></h5>
            <div class="ibox-tools">
              <router-link to="addCustomer">
                <i class="fa fa-plus"></i> &nbsp;&nbsp; Add
              </router-link>
            </div>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" :class="dataTablesCustomer">
                <thead>
                  <tr>
                    <th>{{ customerColumn.id }}</th>
                    <th>{{ customerColumn.name }}</th>
                    <th>{{ customerColumn.authMethod }}</th>
                    <th>{{ customerColumn.description }}</th>
                    <th>{{ customerColumn.action }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="gradeX" v-for="(value, key) in customerData" :key="key">
                    <td style="width: 10%">{{ value.id }}</td>
                    <td style="width: 30%">{{ value.name }}</td>
                    <td>{{ value.authMethod }}</td>
                    <td style="width: 40%">{{ value.description }}</td>
                    <td style="width: 2%">
                      <i class="fas fa-user-cog"></i>
                      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i class="fa fa-wrench"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-user">
                        <li>
                          <router-link
                            :to="{
                              path: '/updateCustomer',
                              query: { id: value.id },
                            }"
                            class="dropdown-item"
                            >Edit</router-link
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#myModal4"
                            @click="addModalData(value)"
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ customerColumn.id }}</th>
                    <th>{{ customerColumn.name }}</th>
                    <th>{{ customerColumn.authMethod }}</th>
                    <th>{{ customerColumn.description }}</th>
                    <th>{{ customerColumn.action }}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <DeleteModal :data="data"></DeleteModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import DeleteModal from "../components/common/DeleteModal";
import EventBus from "../plugin/event-bus";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      customerColumn: {
        id: "Id",
        name: "Customer Name",
        authMethod: "Authentication Method",
        description: "Description",
        action: "Action",
      },
      customerData: [],
      dataTablesCustomer: "dataTables-customer",
      exportTitle: "Customer Details",
      data: {
        title: "Customer",
        name: "",
        id: 0,
        url: Constant.url.CUSTOMER_DELETE,
      },
      selectedCustomerData: {},
    };
  },
  components: {
    DeleteModal,
  },
  created: function () {
    this.fetchCustomerData();
    this.deleteEvent();
  },
  mounted: function () {
    
  },
  methods: {
    fetchCustomerData: function () {
      var self = this;
      var responseCode = Constant.data.RESPONSE_CODE;
      AxiosService.get(Constant.url.CUSTOMER_ALL).then(async (result) => {
        self.customerData = result;
        await DataTableService.loadDataTable(this.dataTablesCustomer, this.exportTitle);
      });
    },
    addModalData: function (value) {
      this.selectedCustomerData = value;
      this.data.name = value.name;
      this.data.id = value.id;
    },
    deleteEvent: function () {
      const self = this;
      EventBus.$on(Constant.data.DELETE_EVENT, (data) => {
        if (data.responseCode == Constant.data.SUCCESS) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.CUSTOMER_ACTIVITY,
              Constant.data.DELETE_TITLE,
              AuditListner.customerAuditTemplate(self.selectedCustomerData)
            )
          );
          self.fetchCustomerData();
          DataTableService.reLoadDataTable(self.dataTablesCustomer);
          DataTableService.loadDataTable(self.dataTablesCustomer, self.exportTitle);
        }
      });
    },
  },
  beforeDestroy() {
    EventBus.$off(Constant.data.DELETE_EVENT);
  },
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
</style>
